import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import moment from 'moment';
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap"; 

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl, carImageUrl, currency, dummycarUrl,categoryURL} from'../Config/Config';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; 

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      datalist:'',
      domainlist:[],
    };
    


    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    

    componentDidMount() {
      var qs = require('qs');
      var admin_id = localStorage.getItem("admin_id");
      var loggedas = localStorage.getItem("loggedas");
      var postobject = {
      activePage: 1,
      search_all: '',
      admin_id: admin_id,
      loggedas: loggedas
      };  
      var url = apiUrl+"AppointmentHistory/past_pendinglist";


      
      axios.post(url,qs.stringify(postobject)).then(res => {
        if(res.data.status === 'success'){
         
            this.setState({  datalist: res.data.datalist, totalRecord: res.data.records_count}, function() {
            
              this.datalist();
            });
  
        }else{
          this.setState({  datalist: '',totalRecord:0}, function() {
            
            this.datalist();
          });
        }
      }); 
   }

   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});

     var qs = require('qs');

     var admin_id = localStorage.getItem("admin_id");
     var loggedas = localStorage.getItem("loggedas");
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
       admin_id: admin_id,
       loggedas:loggedas
     };
    //  this.props.getdatalist(qs.stringify(postobject))
    axios.post(apiUrl+"AppointmentHistory/past_pendinglist",qs.stringify(postobject)).then(res => {
      
      if(res.data.status === 'success'){
          this.setState({  datalist: res.data.datalist, totalRecord: res.data.records_count}, function() {
            this.datalist();
          });

      }
    }); 

   }

   handleInputChange(event) {
   const {name, value} = event.target;      
   this.setState({
     [name]: value
   });
   
 }

 handleFormSubmit = () => {
        
  this.setState({Loading:true});
  var qs = require('qs');
  const formPayload = this.state;
   var admin_id = localStorage.getItem("admin_id");
   var loggedas = localStorage.getItem("loggedas");
  var postObject = {             
    activePage   : 1,
    search_all : formPayload.search_all,
    admin_id: admin_id,
    loggedas: loggedas         
  };
  // console.log(postObject);
  // this.props.getdatalist(qs.stringify(postObject));
  axios.post(apiUrl+"AppointmentHistory/past_pendinglist",qs.stringify(postObject)).then(res => {
    if(res.data.status === 'success'){

        this.setState({  datalist: res.data.datalist, totalRecord: res.data.records_count}, function() {
          this.datalist();
        });

    }else{
      this.setState({  datalist: '',totalRecord:0}, function() {
        
        this.datalist();
      });
    }
  }); 

} 



  /*  componentWillReceiveProps(Props){

 

       if(Props.categoryactive !== this.props.categoryactive){
      if (Props.categoryactive[0]["status"] === "success") {

        $('.success_message').html('<div class="status_sucess"><h3>'+ Props.categoryactive[0]["message"]+'</h3></div>');
              var qs = require('qs');
              var admin_id = localStorage.getItem("admin_id");
              var postobject = {
              activePage: 1,
              search_all: '',
              admin_id: admin_id
              };

              // this.props.getdatalist(qs.stringify(postobject));
               axios.post(apiUrl+"AppointmentHistory/list",qs.stringify(postobject)).then(res => {
    if(res.data.status === 'success'){

        this.setState({  datalist: res.data.datalist, totalRecord: res.data.records_count}, function() {
          this.datalist();
        });

    }
  }); 


           
           setTimeout(
          function() {
          $('.success_message').html('');
          //window.location.reload(); 
          }
          .bind(this),
          3000
          );

      }
    }
     
   } */


datalist() {
  let catImg = '';
  let preview = '';
    var datalist = this.state.datalist;
    if (datalist!== '' || datalist != "undefined" || datalist != null) {

        if (Object.keys(datalist).length > 0) {
          const helperlistDetails = datalist.map(
            (datalist, Index) => {
              let sno = Index+1;
              

               if(datalist.status == 'A'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              if (datalist.cat_image!== null && datalist.cat_image!== '') {
              catImg = categoryURL + "/" + datalist.cat_image;
              preview = <img className="img_class"  src={catImg} alt="" />;
          }   
           var status = datalist.paid_status
          if(datalist.change_status == 'cancel'){
              status = 'Cancelled';
          }
              return (
                <tr key={datalist.id}>
      					<td>{sno}</td>
                <td>{datalist.name}</td>
                <td>{datalist.petname} ({datalist.pettype})</td>
                {/* <td>{datalist.pettype}</td> */}
      					<td>{datalist.doctor_name}</td>
      					<td>{datalist.petvisit}</td>
      					{/* <td>{datalist.book_for}</td> */}
                <td>{moment(datalist.appointment_date).format('DD MMM YYYY')}</td>
      					{/* <td>{datalist.date} {datalist.hours}:{datalist.minutes} {datalist.meridiam}</td> */}
      					{/* <td>{status}</td> */}
      			
               <td className="actiongroup" >                 
                    
                     <Link to={{ pathname: '/appointment-history-view/'+datalist.id}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                    
                  </td>
                </tr>
              );
            }
          );
          return helperlistDetails;
        }
    }else {
      return (
        <tr>
          <td colspan="11" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
  


  render() {

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="FutureAppointmentHistory" /> 
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
   
         <div className="car-listing-wrap leaa-admin-head card">
            <div className="success_message"></div>
              <div className="listing-header">
                <div className="title">
                  <h3>Future Appointments</h3>
                </div>
               

                 <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
                </div> 
                 {/* <div className="add_button">  
                    <a className="" href='/category-add'>Add</a>
                </div> */}
                </div>      
                         
              <div style={{color:"green", fontSize: 30}} className="statusmessage"></div>
            
              <div className="car-listing-row table-avrech">
              <div className="overflow">
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th>S.No</th>
                  <th>Pet Parent</th>
                  <th>Pet</th>
                  <th>Vet</th>
			            <th>Reason</th>
			            {/* <th>Book For</th> */}
			            <th>Schedule On</th>
			            {/* <th>Status</th> */}
                  <th style={{width:"100px"}}>Action</th>
                   </tr>
                  </thead>
                  <tbody>{this.datalist()}</tbody>
                </Table>
			        	</div>
                <div className="text-center">
                  <Pagination
                  activePage={this.state.activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={this.state.totalRecord}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}
                  />
                </div>
              </div>

            </div> 
        </div>  
      </div>
      
    </div>
    );
  }
}
export default (withRouter(List));