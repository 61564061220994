import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Background from '../../common/images/side-bg.jpg';
import loginImg from "../../common/images/login-logo.png";
import QueryContext from '../QueryContext';

class MenuSidebar extends Component {
	static contextType = QueryContext;
	constructor(props)
	{
		super(props);
		this.state = {
			  currentpage:this.props.currentpage,
			  admin_id:localStorage.getItem('admin_id')
		};
		
    }
	
	activeMenu(currentPage) {
        let status = '';
            if(this.state.currentpage===currentPage) {
                status = 'nav-item has-sub active'
            }else{
            status = 'nav-item has-sub enable';
        }
        return status;
    }
	
	handleClick = (url) => {
        // Set a value in localStorage for tracking purposes
        // Open the URL in a new tab
        window.open('/dashboard?ehrpanel=true', '_blank', 'noopener,noreferrer');
	}

  render() {
	const {ehrpanel}  = this.context;
    return (	
	<div className="sidebar-menu mobi-close">
	   {(ehrpanel)?<div className="main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" data-scroll-to-active="true" >
		<div className="navbar-header expanded">
		<ul className="nav navbar-nav flex-row position-relative">
		<li className="nav-item mr-auto">
		<a className="navbar-brand" href="#">
		{/* <img src={loginImg} /> */}
		<h3 className="brand-text">EMR</h3>
		</a>
		</li>
		<li className="nav-item d-md-none"><a href="javascript:void(0)" className="nav-link close-navbar mb-side-nav"><i className="ft-x"></i></a></li>
		</ul>
		</div>
		<div className="navigation-background" style={{background: `url(${Background})`}}></div>
		<div className="main-menu-content ps ps--active-y" style={{height: "587px;"}}>
		<Scrollbars className="custom-scroll-wrap">
		<ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
		<li className={this.activeMenu('dashboard')}>
		<a href="/dashboard?ehrpanel=true">
		<i className="ft-home"></i>
		<span className="menu-title" data-i18n="">Dashboard</span>
		</a>
		</li>

		<li className={this.activeMenu('user')}>
		<a href="/user?ehrpanel=true">
		<i className="ft-users"></i>
		<span className="menu-title" data-i18n="">Pet Parents</span>
		</a>
		</li>

		<li className={this.activeMenu('doctors')}>
		<a href="/doctors?ehrpanel=true">
		<i className="ft-users-vets"></i>
		<span className="menu-title" data-i18n="">Vets</span>
		</a>
		</li>

		<li className={this.activeMenu('technicians')}>
		<a href="/technicians?ehrpanel=true">
		<i className="ft-users-vets"></i>
		<span className="menu-title" data-i18n="">Technicians</span>
		</a>
		</li>

		<li className={this.activeMenu('FutureAppointmentHistory')}>
		<a href="/future-appointment-history?ehrpanel=true">
			<i className="ft-server" aria-hidden="true"></i>
			<span className="menu-title" data-i18n="">Future Appointments</span>
		</a>
		</li>

		<li className={this.activeMenu('OngoingAppointmentHistory')}>
		<a href="/ongoing-appointment-history?ehrpanel=true">
			<i className="ft-server" aria-hidden="true"></i>
			<span className="menu-title" data-i18n="">Ongoing Appointments</span>
		</a>
		</li>
		<li className={this.activeMenu('PastAppointmentHistory')}>
		<a href="/past-appointment-history?ehrpanel=true">
			<i className="ft-server" aria-hidden="true"></i>
			<span className="menu-title" data-i18n="">Past Appointments</span>
		</a>
		</li>


		{/* <li className={this.activeMenu('user')}>
		<a href="/user?ehrpanel=true">
		<i className="ft-users"></i>
		<span className="menu-title" data-i18n="">Pet Parent</span>
		</a>
		</li>

		<li className={this.activeMenu('doctors')}>
		<a href="/doctors?ehrpanel=true">
		<i className="ft-users"></i>
		<span className="menu-title" data-i18n="">Providers</span>
		</a>
		</li>


		<li className={this.activeMenu('AppointmentHistory')}>
		<a href="/appointment-history?ehrpanel=true">
			<i className="ft-server" aria-hidden="true"></i>
			<span className="menu-title" data-i18n="">Appointments</span>
		</a>
		</li> */}


		</ul>
		</Scrollbars>
		</div>
		</div>	
		:<div className="main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" data-scroll-to-active="true" >
  <div className="navbar-header expanded">
    <ul className="nav navbar-nav flex-row position-relative">
      <li className="nav-item mr-auto">
        <a className="navbar-brand" href="#">
        {/* <img src={loginImg} /> */}
          <h3 className="brand-text">Admin</h3>
        </a>
      </li>
      <li className="nav-item d-md-none"><a href="javascript:void(0)" className="nav-link close-navbar mb-side-nav"><i className="ft-x"></i></a></li>
    </ul>
  </div>
  <div className="navigation-background" style={{background: `url(${Background})`}}></div>
  <div className="main-menu-content ps ps--active-y" style={{height: "587px;"}}>
  <Scrollbars className="custom-scroll-wrap">
    <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
      <li className={this.activeMenu('dashboard')}>
        <a href="/dashboard">
          <i className="ft-home"></i>
          <span className="menu-title" data-i18n="">Dashboard</span>
        </a>
      </li>

	  

	  <li className={this.activeMenu('EMR')}>
		<a href="javascript://" onClick={this.handleClick.bind(this)} target='_blank'>
		<i className="ft-home"></i>
		<span className="menu-title" data-i18n="">EMR Panel</span>
		</a>
		</li>
     <li className={this.activeMenu('user')}>
        <a href="/user">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Users</span>
        </a>
      </li>
	  <li className={this.activeMenu('referaluserlist')}>
					<a href="/referaluserlist">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Invited Users</span>
					</a>
				</li>
       			 <li className={this.activeMenu('InviteDiscount')}>
					<a href="/InviteDiscount">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Manage Invite Discounts</span>
					</a>
				</li>
       
      {this.state.admin_id == 1 ?<li className={this.activeMenu('companylist')}>
					<a title="Company" href="/company">
						<i className="ft-users" aria-hidden="true"></i>
						<span>Companies</span>
					</a>
		        </li>:''}  
      {/* <li className={this.activeMenu('FamilyRelation')}>
        <a href="/FamilyRelation">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Family Relationship</span>
        </a>
      </li> */}
      <li className={this.activeMenu('doctors')}>
        <a href="/doctors">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Vets</span>
        </a>
      </li>
      <li className={this.activeMenu('technicians')}>
        <a href="/technicians">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Technicians</span>
        </a>
      </li>
	  <li className={this.activeMenu('DashboardManagement')}>
					<a title="Dashboard Management Builder" href="/dashboardmanagement">
						<i className="ft-server"></i>
						<span>Dashboard Management</span>
					</a>
				</li>

				<li className={this.activeMenu('shortcuticon')}>
			<a title="Shortcut icon" href="/shortcuticon">
				<i className="ft-server"></i>
				<span>ShortCut Icon</span>
			</a>
		</li>
		
      <li className={this.activeMenu('Category')}>
        <a href="/category">
          <i className="ft-server"></i>
          <span className="menu-title" data-i18n="">Category</span>
        </a>
      </li> 
      <li className={this.activeMenu('ProductCategory')}>
					<a href="/ProductCategory">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Product Category</span>
					</a>
				</li>
      <li className={this.activeMenu('Product')}>
					<a href="/product">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Products</span>
					</a>
				</li>
				<li className={this.activeMenu('Providerinventory')}>
					<a href="/providerinventory">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Vets Products</span>
					</a>
				</li>
				<li className={this.activeMenu('requestinventory')}>
					<a href="/requestinventory">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Refill Vets Products</span>
					</a>
				</li>

				<li className={this.activeMenu('rolemanagement')}>
					<a href="/rolemanagement">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Role Management</span>
					</a>
				</li>
				<li className={this.activeMenu('userrolemanagement')}>
					<a href="/userrolemanagement">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">User Role</span>
					</a>
				</li>
        <li className={this.activeMenu('Prescription')}>
					<a href="/prescription">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Prescription Management</span>
					</a>
				</li>
        <li className={this.activeMenu('MembershipCategory')}>
					<a href="/Membershipcategory">
						<i className="ft-users" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Membership Category</span>
					</a>
				</li>
        <li className={this.activeMenu('Membership')}>
					<a href="/Membership">
						<i className="ft-users" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Membership</span>
					</a>
				</li>
        <li className={this.activeMenu('subscribers')}>
					<a href="/subscribers">
						<i className="ft-users" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Subscribers</span>
					</a>
				</li>

			<li className={this.activeMenu('advertisementcategory')}>
				<a href="/AdvertisementCategory">
					<i className="ft-faq-category" aria-hidden="true"></i>
					<span className="menu-title" data-i18n="">AdvertisementCategory</span>
				</a>
			</li>

			<li className={this.activeMenu('advertisement')}>
				<a href="/Advertisement">
					<i className="ft-faq-category" aria-hidden="true"></i>
					<span className="menu-title" data-i18n="">Advertisement</span>
				</a>
			</li>
			<li className={this.activeMenu('offers')}>
				<a href="/offers">
					<i className="ft-faq-category" aria-hidden="true"></i>
					<span className="menu-title" data-i18n="">Offers</span>
				</a>
			</li>
        <li className={this.activeMenu('FaqCategory')}>
					<a href="/Faqcategory">
						<i className="ft-faq-category" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Faq Category</span>
					</a>
				</li>

       <li className={this.activeMenu('Faq')}>
					<a href="/Faq">
						<i className="ft-faq" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Faq</span>
					</a>
				</li>

        <li className={this.activeMenu('Aifeed')}>
					<a href="/aifeed">
						<i className="ft-faq" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Manage AI Feed</span>
					</a>
				</li>

        <li className={this.activeMenu('AppointmentHistory')}>
					<a href="/appointment-history">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Appointment History</span>
					</a>
			</li>
        <li className={this.activeMenu('ProductBookingHistory')}>
					<a href="/product-booking-history">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Product Order History</span>
					</a>
				</li>
				
		<li className={this.activeMenu('raisedispute')}>
			<a href="/RaiseDispute">
				<i className="ft-server" aria-hidden="true"></i>
				<span className="menu-title" data-i18n="">Raise Dispute</span>
			</a>
		</li>
      {/*

      <li className={this.activeMenu('Category')}>
					<a href="/category">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Category</span>
					</a>
				</li>
        <li className={this.activeMenu('ProductCategory')}>
					<a href="/ProductCategory">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Product Category</span>
					</a>
				</li>
      

     

        

       
      <li className={this.activeMenu('DoctorVisitManagement')}>
					<a href="/doctor-visite-management">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Doctor Visit Management</span>
					</a>
			</li> */}
      			<li className={this.activeMenu('abusiveword')}>
					<a href="/abusivewords">
						<i className="ft-edit" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Abusive Words</span>
					</a>
				</li>
      <li className={this.activeMenu('plugincontent')}>
					<a href="/plugincontent">
						<i className="ft-edit" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Plugin Content</span>
					</a>
				</li>


      <li className={this.activeMenu('fonts')}>
        <a href="/fonts">
          <i className="ft-type"></i>
          <span className="menu-title" data-i18n="">Fonts</span>
        </a>
      </li>
      {this.state.admin_id == 1 ?<li className={this.activeMenu('templates')}>
        <a href="/templates">
          <i className="ft-feather"></i>
          <span className="menu-title" data-i18n="">Templates</span>
        </a>
        </li>:''} 
        <li className={this.activeMenu('Location')}>
					<a href="/Location">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Location</span>
					</a>
				</li>
        <li className={this.activeMenu('Guide')}>
					<a href="/Guide">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Guide</span>
					</a>
				</li>
	  <li className={this.activeMenu('setting')}>
        <a href="/setting">
          <i className="ft-settings"></i>
          <span className="menu-title" data-i18n="">Settings</span>
        </a>
      </li>
    </ul>
    </Scrollbars>
  </div>
		</div>}
	</div>		

	
    );
  }
}

export default MenuSidebar;