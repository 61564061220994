import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import QueryContext from '../QueryContext';
class Header extends Component {
	static contextType = QueryContext;
	constructor(props)
	{
		super(props);	
		this.state = {
			admin_name : localStorage.getItem("admin_name"),
			admin_id : localStorage.getItem("admin_id"),
			admin_company_name : localStorage.getItem("admin_company_name"),
		};
		    this.logout  = this.logout.bind(this);

    }
    logout(){
    	localStorage.removeItem('admin_id');
    	localStorage.removeItem('admin_name');
    	localStorage.removeItem('loggedas');
    	localStorage.removeItem('admin_company_name');
    	this.props.history.push('/logout');
      }
	
	componentDidMount() {
		if (localStorage.getItem("admin_id") === null) {
			this.props.history.push('/');
		}
    }
	
  render() {
	const {ehrpanel}  = this.context;
    return (
	<div className="header">	
	  <div className="navbar-wrapper">
	  
		   {/* <div className="logo-box mobi-close">
				<a href="javascript:void(0);" className="logo">
					{(localStorage.getItem('loggedas') === 'admin') && <span className="logo-lg">{this.state.admin_company_name && this.state.admin_company_name}</span>
					}
				</a>
			</div>	*/}
			
			<div className="header-cnt">
			<div className="header-cnt-left">			
				<a href="javascript:void(0)" className="trigger-menu menu-icon">
                    <span className="icon_menu1"></span>
                    <span className="icon_menu2"></span>
                    <span className="icon_menu3"></span>
                </a>	
			</div>
			{ehrpanel?<div className="myaccount">	
				<div className="myaccount-inner"><span className="user-name">EMR PANEL</span></div></div>:<div className="header-cnt-right">	
				<div className="myaccount">	
				<div className="myaccount-inner">
					<a href="javascript:void(0)" alt="user" className="user-name" >Welcome! {this.state.admin_name}</a>
					<a href="javascript:void(0)" alt="mail" className="dropdown-toggle user-icon r-icon user-main-head">
						<i className="ft-user"></i>
					</a>
				</div>	
					<ul className="user-menu">
						{localStorage.getItem('loggedas') === 'admin' && <li>
							<a className="user-username" href="/profile" title="Upload Docs">
								<i className="ft-user"></i> <span>Profile</span>
							</a>
						</li>}
						<li>
							<a className="user-username" href="/changepassword" title="Upload Docs">
								<i className="ft-lock"></i> <span>Change Password</span>
							</a>
						</li>
						<li>
						 <a className="user-username"
                                href="javascript:void(0);"
                                onClick={e => {
                                  e.preventDefault();
                                  this.logout();
                                }}
                              >
								<i className="ft-log-out"></i> <span>Logout</span>
							</a>
						</li>
					</ul>
				</div>	
			</div>		}
			</div>			
			
		</div>
	</div>
	
    );
  }
}


const mapStateTopProps = (state) => {
  return {
	
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
	
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(withRouter(Header));